import { Course } from "@/types";
import { Link } from "@inertiajs/react";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CourseCard from "../CourseCard";

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 1024 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 1024, min: 768 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 768, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

function LandingCoursesCarousel({ courses }: { courses: Course[] }) {
    const { t, i18n } = useTranslation();

    const controls = useAnimation();
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1,
            x: 0,
            transition: { duration: 0.5 },
        },
    };

    return (
        <div className="relative w-full">
            <motion.div
                ref={ref}
                initial="hidden"
                animate={controls}
                variants={variants}
            >
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // Server-side rendering support
                    infinite={true}
                    keyBoardControl={true}
                    transitionDuration={400}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={[
                        "tablet",
                        "mobile",
                        "desktop",
                        "superLargeDesktop",
                    ]}
                    dotListClass="flex justify-center p-2"
                    itemClass="carousel-item-padding-40-px"
                    renderButtonGroupOutside={true}
                    className="mx-3 py-9"
                    customDot={<CustomDot onClick={() => {}} active={false} />}
                    rtl={i18n.language === "ar"}
                >
                    {courses?.map((course, index) => (
                        <motion.div
                            key={index}
                            className="min-w-full px-2"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 }}
                        >
                            <Link href={route("courses.show", course.id)}>
                                <CourseCard
                                    title={course.title}
                                    description={course.description}
                                    cover={course.thumbnail_url}
                                    user={course.instructor}
                                />
                            </Link>
                        </motion.div>
                    ))}
                </Carousel>
            </motion.div>
        </div>
    );
}

interface CustomDotProps {
    onClick: () => void;
    active: boolean;
}

const CustomDot: React.FC<CustomDotProps> = ({ onClick, active }) => {
    return (
        <li
            className={`inline-block w-3 h-3 rounded-full mx-1 transition-colors duration-300 ${
                active ? "bg-PrimaryBlue w-5 h-1" : "bg-blue-200 w-3 h-1"
            }`}
            onClick={onClick}
        />
    );
};

export default LandingCoursesCarousel;
